import {Injectable} from '@angular/core';
import {io, Socket} from "socket.io-client";
import {ApiService} from "./api.service";
import {Subject} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  public data: Subject<any> = new Subject<any>();
  public connectionStatus: Subject<any> = new Subject<any>();
  public connectionStatusString: string = 'offline';


  private socketUser: Socket|null = null;

  constructor(
    private apiService: ApiService
  ) {
  }

  public connect(user_id: number): void {
    /*if (this.socketUser) {
      this.socketUser.disconnect();
      this.socketUser = null;
    }

    this.socketUser = io(this.apiService.getUrl() + ':6001', {
      reconnectionDelay: 10000,
      reconnection: environment.production
    });

    this.socketUser.emit('joinRoom', '/usr-' + user_id);

    console.log('Socket - Init');
    this.socketUser.on('updateBell', (data) => {
      console.log('Socket - Bell Update');
      this.data.next(data);
    });

    this.socketUser.on('connect_error', () => {
      //console.log('Socket - Connect Error');
      this.connectionStatus.next('offline');
      this.connectionStatusString = 'offline';
    });

    this.socketUser.on('connect', () => {
      console.log('Socket - Connect Success');
      this.connectionStatus.next('online');
      this.connectionStatusString = 'online';
    });*/

  }

  public disconnect(): void {
    if (this.socketUser) {
      this.socketUser.disconnect();
    }
  }

  public testUpdateBell(): void {
    if (this.socketUser) {
      this.socketUser.emit('updateBell', {message: 'Please update the bell'});
    }
  }
}
